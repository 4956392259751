<template>
  <a-form-model ref="form" :model="form" v-bind="$formItemLayout">
    <a-form-model-item
      label="图片"
      prop="coverUrl"
      :rules="{
        required: true,
        message: '请上传图片',
        trigger: 'blur'
      }"
    >
      <image-upload :url="form.coverUrl" @success="handleCoverUrl" @remove="form.coverUrl = ''"></image-upload>
    </a-form-model-item>

    <a-form-model-item label="视频介绍" prop="videoUrl">
      <single-upload :accept="['mp4']" :limit="1024 * 1024 * 200" @success="handleVideoUrl"></single-upload>

      <a-row v-if="form.videoUrl" style="margin:8px 0;line-height:normal" type="flex" align="middle">
        <a-col flex="1" style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;">
          <a
            @click="handlePreviewVideo(form.videoUrl)"
            target="blank_"
            style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
          >
            <a-icon type="file" style="margin-right:8px" />{{ form.videoUrl }}
          </a>
        </a-col>
        <a-col class="error-color" style="margin-left:16px;cursor:pointer" @click="form.videoUrl = ''">
          <a-icon type="delete" class="error-color" />
        </a-col>
      </a-row>

      <div class="disabled-color" style="line-height:1.5">
        提示：MP4格式文件，大小不超过200MB
      </div>
    </a-form-model-item>

    <a-form-model-item
      label="技能名称"
      prop="name"
      :rules="{
        required: true,
        message: '请填写技能名称',
        trigger: 'blur'
      }"
    >
      <a-input v-model.trim="form.name" placeholder="请填写技能名称" :maxLength="25" />
    </a-form-model-item>

    <a-form-model-item
      label="所属领域"
      prop="fieldId"
      :rules="
        form.id
          ? {}
          : {
              required: true,
              message: '请选择所属领域',
              trigger: 'blur'
            }
      "
    >
      <h-cascader
        v-if="!form.id"
        v-model="form.fieldId"
        :options="fieldsOptions"
        :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
        placeholder="请选择"
      />
      <span v-else>
        {{
          form.field.name
            ? form.field.parent
              ? form.field.parent.name + ' > ' + form.field.name
              : form.field.name
            : '-'
        }}
      </span>
    </a-form-model-item>

    <a-form-model-item
      label="所属等级"
      prop="level"
      :rules="
        form.id
          ? { required: false }
          : {
              required: true,
              message: '请选择所属等级',
              trigger: 'blur'
            }
      "
    >
      <h-select v-if="!form.id" v-model="form.level" allowClear placeholder="请选择">
        <a-select-option v-for="levelInfo in levelOptions" :key="levelInfo.value" :value="levelInfo.value">
          {{ levelInfo.label }}
        </a-select-option>
      </h-select>
      <span v-else>{{ form.level | SkillLevelText() }}</span>
    </a-form-model-item>

    <a-form-model-item
      label="适合年龄"
      prop="suitableAge"
      :rules="
        form.id
          ? { required: false }
          : {
              required: true,
              message: '请选择适合年龄',
              trigger: 'blur'
            }
      "
    >
      <h-select v-if="!form.id" v-model="form.suitableAge" placeholder="请选择">
        <a-select-option
          v-for="suitableAgeInfo in suitableAgeOptions"
          :key="suitableAgeInfo.value"
          :value="suitableAgeInfo.value"
        >
          {{ suitableAgeInfo.label }}
        </a-select-option>
      </h-select>
      <span v-else>{{ form.suitableAge | SuitableAgeText() }}</span>
    </a-form-model-item>

    <a-form-model-item label="基础技能">
      <a-radio-group v-model="form.isBaseSkill">
        <a-radio :value="false">否</a-radio>
        <a-radio :value="true">是</a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item label="技能类型">
      <a-radio-group v-model="form.type">
        <a-radio v-for="typeInfo in typeOptions" :key="typeInfo.value" :value="typeInfo.value">
          {{ typeInfo.label }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item
      label="技能描述"
      prop="description"
      :rules="{
        required: true,
        message: '请填写技能描述',
        trigger: ['change', 'blur']
      }"
    >
      <div style="position:relative">
        <a-input
          v-model="form.description"
          placeholder="请输入内容"
          :maxLength="300"
          :autoSize="{ minRows: 3, maxRows: 3 }"
          type="textarea"
        />
        <div style="position:absolute;bottom:-5px;right:1px;">
          <span style="background:#fff">{{ form.description.length }}/300</span>
        </div>
      </div>
    </a-form-model-item>

    <a-form-model-item
      label="训练目标"
      prop="goal"
      :rules="{
        required: true,
        message: '请填写训练目标',
        trigger: ['change', 'blur']
      }"
    >
      <div style="position:relative">
        <a-input
          v-model="form.goal"
          placeholder="请输入内容"
          :maxLength="300"
          :autoSize="{ minRows: 3, maxRows: 3 }"
          type="textarea"
        />
        <div style="position:absolute;bottom:-5px;right:1px;">
          <span style="background:#fff">{{ form.goal.length }}/300</span>
        </div>
      </div>
    </a-form-model-item>
    <a-form-model-item label="训练概述">
      <div style="position:relative">
        <a-input
          v-model="form.introduction"
          placeholder="请输入内容"
          :maxLength="500"
          :autoSize="{ minRows: 6, maxRows: 6 }"
          type="textarea"
        />
        <div style="position:absolute;bottom:-5px;right:1px;">
          <span style="background:#fff">{{ form.introduction.length }}/500</span>
        </div>
      </div>
    </a-form-model-item>
    <a-form-model-item label="先备技能">
      <div style="position:relative">
        <a-input
          v-model="form.prerequisite"
          placeholder="请输入内容"
          :maxLength="700"
          :autoSize="{ minRows: 8, maxRows: 8 }"
          type="textarea"
        />
        <div style="position:absolute;bottom:-5px;right:1px;">
          <span style="background:#fff">{{ form.prerequisite.length }}/700</span>
        </div>
      </div>
    </a-form-model-item>

    <preparation-form-list ref="preparation" title="学前准备"></preparation-form-list>

    <a-form-model-item v-bind="$formItemLayoutWithoutLabel" style="margin-bottom: 64px">
      <a-button type="dashed" style="width: 100%" @click="addPreparation"> <a-icon type="plus" />新增学前准备</a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { cloneDeep } from 'lodash'
import * as commonApi from '@/api/common'
import { previewVideo } from '@/utils/util'
import { SKILL_LEVEL, SUITABLE_AGE, SKILL_TYPE } from '@/store/dictionary'
import { ImageUpload, SingleUpload } from '@/components/_heath'
import PreparationFormList from './PreparationFormList'

export default {
  components: {
    ImageUpload,
    SingleUpload,
    PreparationFormList
  },
  data() {
    return {
      form: {
        id: undefined,
        coverUrl: '',
        videoUrl: '',
        name: undefined,
        field: {},
        fieldId: [],
        level: undefined,
        suitableAge: undefined,
        isBaseSkill: true,
        type: 'NONE',
        description: '',
        goal: '',
        introduction: '',
        prerequisite: '',
        trainingPreparationList: []
      },
      fieldsOptions: [],
      levelOptions: SKILL_LEVEL,
      suitableAgeOptions: SUITABLE_AGE,
      typeOptions: SKILL_TYPE
    }
  },
  activated() {
    this.initOptions()
  },
  methods: {
    initData(form) {
      const {
        id,
        coverUrl,
        videoUrl,
        name,
        field,
        level,
        suitableAge,
        isBaseSkill,
        type,
        description,
        goal,
        introduction,
        prerequisite,
        trainingPreparationList
      } = form

      if (trainingPreparationList.length > 0) {
        this.$refs.preparation.initData(trainingPreparationList)
      }

      this.form = {
        id,
        coverUrl,
        videoUrl,
        name,
        field,
        level,
        suitableAge,
        isBaseSkill,
        type,
        description,
        goal,
        introduction,
        prerequisite,
        trainingPreparationList
      }
    },
    initOptions() {
      commonApi.fieldsOptions().then(res => {
        this.fieldsOptions = res.data.filter(v => v.level === 'MAIN').filter(v => v.children.length > 0)
      })
    },
    validate() {
      const {
        $refs: { form, preparation }
      } = this

      const basisValid = new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
      const valids = [basisValid]

      const preprationValid = preparation.validate()
      if (preprationValid.length > 0) {
        valids.push(...preprationValid)
      }

      return Promise.all(valids)
    },
    formatForm() {
      const ret = cloneDeep(this.form)

      let { fieldId } = ret
      const { field } = ret
      if (ret.id) {
        fieldId = field.id
        delete ret.field
      } else {
        fieldId = fieldId[1]
      }

      const trainingPreparationList = this.$refs.preparation.formatForm()

      return { ...ret, fieldId, trainingPreparationList }
    },
    handleCoverUrl(url) {
      const {
        $refs: { form }
      } = this

      this.form.coverUrl = url

      form.clearValidate('coverUrl')
    },
    // 上传的文件信息
    handleVideoUrl(uploadInfo) {
      this.form.videoUrl = uploadInfo.url
    },
    addPreparation() {
      this.$refs.preparation.add()
    },
    //
    handlePreviewVideo(url) {
      previewVideo(url)
    }
  }
}
</script>

<style lang="less" scoped></style>
