var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',_vm._b({ref:"form",attrs:{"model":_vm.form}},'a-form-model',_vm.$formItemLayout,false),[_c('a-form-model-item',{attrs:{"label":"图片","prop":"coverUrl","rules":{
      required: true,
      message: '请上传图片',
      trigger: 'blur'
    }}},[_c('image-upload',{attrs:{"url":_vm.form.coverUrl},on:{"success":_vm.handleCoverUrl,"remove":function($event){_vm.form.coverUrl = ''}}})],1),_c('a-form-model-item',{attrs:{"label":"视频介绍","prop":"videoUrl"}},[_c('single-upload',{attrs:{"accept":['mp4'],"limit":1024 * 1024 * 200},on:{"success":_vm.handleVideoUrl}}),(_vm.form.videoUrl)?_c('a-row',{staticStyle:{"margin":"8px 0","line-height":"normal"},attrs:{"type":"flex","align":"middle"}},[_c('a-col',{staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"},attrs:{"flex":"1"}},[_c('a',{staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"},attrs:{"target":"blank_"},on:{"click":function($event){return _vm.handlePreviewVideo(_vm.form.videoUrl)}}},[_c('a-icon',{staticStyle:{"margin-right":"8px"},attrs:{"type":"file"}}),_vm._v(_vm._s(_vm.form.videoUrl)+" ")],1)]),_c('a-col',{staticClass:"error-color",staticStyle:{"margin-left":"16px","cursor":"pointer"},on:{"click":function($event){_vm.form.videoUrl = ''}}},[_c('a-icon',{staticClass:"error-color",attrs:{"type":"delete"}})],1)],1):_vm._e(),_c('div',{staticClass:"disabled-color",staticStyle:{"line-height":"1.5"}},[_vm._v(" 提示：MP4格式文件，大小不超过200MB ")])],1),_c('a-form-model-item',{attrs:{"label":"技能名称","prop":"name","rules":{
      required: true,
      message: '请填写技能名称',
      trigger: 'blur'
    }}},[_c('a-input',{attrs:{"placeholder":"请填写技能名称","maxLength":25},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"所属领域","prop":"fieldId","rules":_vm.form.id
        ? {}
        : {
            required: true,
            message: '请选择所属领域',
            trigger: 'blur'
          }}},[(!_vm.form.id)?_c('h-cascader',{attrs:{"options":_vm.fieldsOptions,"fieldNames":{ label: 'name', value: 'id', children: 'children' },"placeholder":"请选择"},model:{value:(_vm.form.fieldId),callback:function ($$v) {_vm.$set(_vm.form, "fieldId", $$v)},expression:"form.fieldId"}}):_c('span',[_vm._v(" "+_vm._s(_vm.form.field.name ? _vm.form.field.parent ? _vm.form.field.parent.name + ' > ' + _vm.form.field.name : _vm.form.field.name : '-')+" ")])],1),_c('a-form-model-item',{attrs:{"label":"所属等级","prop":"level","rules":_vm.form.id
        ? { required: false }
        : {
            required: true,
            message: '请选择所属等级',
            trigger: 'blur'
          }}},[(!_vm.form.id)?_c('h-select',{attrs:{"allowClear":"","placeholder":"请选择"},model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}},_vm._l((_vm.levelOptions),function(levelInfo){return _c('a-select-option',{key:levelInfo.value,attrs:{"value":levelInfo.value}},[_vm._v(" "+_vm._s(levelInfo.label)+" ")])}),1):_c('span',[_vm._v(_vm._s(_vm._f("SkillLevelText")(_vm.form.level)))])],1),_c('a-form-model-item',{attrs:{"label":"适合年龄","prop":"suitableAge","rules":_vm.form.id
        ? { required: false }
        : {
            required: true,
            message: '请选择适合年龄',
            trigger: 'blur'
          }}},[(!_vm.form.id)?_c('h-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.form.suitableAge),callback:function ($$v) {_vm.$set(_vm.form, "suitableAge", $$v)},expression:"form.suitableAge"}},_vm._l((_vm.suitableAgeOptions),function(suitableAgeInfo){return _c('a-select-option',{key:suitableAgeInfo.value,attrs:{"value":suitableAgeInfo.value}},[_vm._v(" "+_vm._s(suitableAgeInfo.label)+" ")])}),1):_c('span',[_vm._v(_vm._s(_vm._f("SuitableAgeText")(_vm.form.suitableAge)))])],1),_c('a-form-model-item',{attrs:{"label":"基础技能"}},[_c('a-radio-group',{model:{value:(_vm.form.isBaseSkill),callback:function ($$v) {_vm.$set(_vm.form, "isBaseSkill", $$v)},expression:"form.isBaseSkill"}},[_c('a-radio',{attrs:{"value":false}},[_vm._v("否")]),_c('a-radio',{attrs:{"value":true}},[_vm._v("是")])],1)],1),_c('a-form-model-item',{attrs:{"label":"技能类型"}},[_c('a-radio-group',{model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.typeOptions),function(typeInfo){return _c('a-radio',{key:typeInfo.value,attrs:{"value":typeInfo.value}},[_vm._v(" "+_vm._s(typeInfo.label)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"label":"技能描述","prop":"description","rules":{
      required: true,
      message: '请填写技能描述',
      trigger: ['change', 'blur']
    }}},[_c('div',{staticStyle:{"position":"relative"}},[_c('a-input',{attrs:{"placeholder":"请输入内容","maxLength":300,"autoSize":{ minRows: 3, maxRows: 3 },"type":"textarea"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticStyle:{"position":"absolute","bottom":"-5px","right":"1px"}},[_c('span',{staticStyle:{"background":"#fff"}},[_vm._v(_vm._s(_vm.form.description.length)+"/300")])])],1)]),_c('a-form-model-item',{attrs:{"label":"训练目标","prop":"goal","rules":{
      required: true,
      message: '请填写训练目标',
      trigger: ['change', 'blur']
    }}},[_c('div',{staticStyle:{"position":"relative"}},[_c('a-input',{attrs:{"placeholder":"请输入内容","maxLength":300,"autoSize":{ minRows: 3, maxRows: 3 },"type":"textarea"},model:{value:(_vm.form.goal),callback:function ($$v) {_vm.$set(_vm.form, "goal", $$v)},expression:"form.goal"}}),_c('div',{staticStyle:{"position":"absolute","bottom":"-5px","right":"1px"}},[_c('span',{staticStyle:{"background":"#fff"}},[_vm._v(_vm._s(_vm.form.goal.length)+"/300")])])],1)]),_c('a-form-model-item',{attrs:{"label":"训练概述"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('a-input',{attrs:{"placeholder":"请输入内容","maxLength":500,"autoSize":{ minRows: 6, maxRows: 6 },"type":"textarea"},model:{value:(_vm.form.introduction),callback:function ($$v) {_vm.$set(_vm.form, "introduction", $$v)},expression:"form.introduction"}}),_c('div',{staticStyle:{"position":"absolute","bottom":"-5px","right":"1px"}},[_c('span',{staticStyle:{"background":"#fff"}},[_vm._v(_vm._s(_vm.form.introduction.length)+"/500")])])],1)]),_c('a-form-model-item',{attrs:{"label":"先备技能"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('a-input',{attrs:{"placeholder":"请输入内容","maxLength":700,"autoSize":{ minRows: 8, maxRows: 8 },"type":"textarea"},model:{value:(_vm.form.prerequisite),callback:function ($$v) {_vm.$set(_vm.form, "prerequisite", $$v)},expression:"form.prerequisite"}}),_c('div',{staticStyle:{"position":"absolute","bottom":"-5px","right":"1px"}},[_c('span',{staticStyle:{"background":"#fff"}},[_vm._v(_vm._s(_vm.form.prerequisite.length)+"/700")])])],1)]),_c('preparation-form-list',{ref:"preparation",attrs:{"title":"学前准备"}}),_c('a-form-model-item',_vm._b({staticStyle:{"margin-bottom":"64px"}},'a-form-model-item',_vm.$formItemLayoutWithoutLabel,false),[_c('a-button',{staticStyle:{"width":"100%"},attrs:{"type":"dashed"},on:{"click":_vm.addPreparation}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("新增学前准备")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }